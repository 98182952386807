<script setup>
import { computed, markRaw } from "vue";
import { useRoute } from "vue-router";
import { useHead } from "@vueuse/head";
import { useSessionStore } from "@/stores/session";
import DashboardOverview from "@/components/dashboard/DashboardOverview.vue";
import ReferralsOverview from "@/components/dashboard/ReferralsOverview.vue";
import ApiKeyOverview from "@/components/dashboard/ApiKeyOverview.vue";
import AlertsOverview from "@/components/dashboard/AlertsOverview.vue";
import ChatOverview from "@/components/dashboard/ChatOverview.vue";

const route = useRoute();
// const router = useRouter();
const sessionStore = useSessionStore();

const toolComponents = {
  overview: markRaw(DashboardOverview),
  referrals: markRaw(ReferralsOverview),
  apikey: markRaw(ApiKeyOverview),
  alerts: markRaw(AlertsOverview),
  chat: markRaw(ChatOverview),
  // Add more tools here as needed
};

const currentTool = computed(() => route.params.tool || "overview");

const pageTitle = computed(() => {
  switch (currentTool.value) {
    case "overview":
      return "DASHBOARD";
    case "chat":
      return "CHAT";
    case "referrals":
      return "REFERRALS";
    case "apikey":
      return "API KEY";
    case "alerts":
      return "ALERTS";
    default:
      return "DASHBOARD";
  }
});

const isLoggedIn = computed(() => sessionStore.isLoggedIn);

useHead({
  title: computed(() => `${pageTitle.value} | Two Bros Inferencing`),
  meta: [
    {
      name: "description",
      content: "View and manage your Two Bros Inferencing dashboard.",
    },
    {
      property: "og:description",
      content: "View and manage your Two Bros Inferencing dashboard.",
    },
    {
      property: "og:image",
      content:
        "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
    },
  ],
});

// const goBack = () => {
//   router.push({ name: "Dashboard", params: { tool: "overview" } });
// };
</script>

<template>
  <div
    class="container mx-auto py-4 px-8 w-full mt-24 md:mt-46 overflow-x-hidden"
    style="min-height: 100vh"
  >
    <div v-if="isLoggedIn">
      <!-- <div v-if="currentTool !== 'overview'" class="mb-4">
        <button
          @click="goBack"
          class="w-40 px-4 py-2 bg-neutral-focus text-base-content rounded hover:bg-base-100 transition duration-300"
        >
          <font-awesome-icon icon="fa-solid fa-arrow-left" class="mr-2" />
          Dashboard
        </button>
      </div> -->

      <!-- <div v-if="currentTool === 'overview'" class="mb-4 h-10"></div> -->

      <div class="text-center mb-8 p-0 overflow-x-hidden">
        <h1 class="text-4xl lg:text-6xl text-base-content">
          {{ pageTitle }}
        </h1>
      </div>

      <component
        :is="toolComponents[currentTool] || toolComponents.overview"
      ></component>
    </div>

    <div v-else class="text-center text-base-content">
      <p>You are not logged in.</p>
      <button
        class="mt-4 px-8 py-2 bg-neutral-focus text-base-content rounded hover:bg-base-100 transition duration-300"
      >
        <router-link :to="'/login'"> Login </router-link>
      </button>
    </div>
  </div>
</template>
