<template>
  <div class="relative">
    <textarea
      id="prompt-input"
      ref="textareaRef"
      v-model="prompt"
      rows="1"
      :placeholder="
        disabled
          ? 'Please wait for a response...'
          : 'Try our Inference Engine by sending a message...'
      "
      :disabled="disabled"
      class="w-full bg-base-100 text-base-content placeholder-base-content rounded-lg py-3 pl-4 pr-10 focus:outline-none focus:ring-2 focus:ring-primary resize-none overflow-hidden disabled:opacity-50 disabled:cursor-not-allowed"
      @input="adjustTextareaHeight"
      @keydown="handleKeyDown"
    ></textarea>
    <button
      class="absolute right-3 bottom-3 text-base-content hover:text-base-content disabled:opacity-50 disabled:cursor-not-allowed"
      @click="submitPrompt"
      :disabled="disabled || !prompt.trim()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, defineExpose, defineEmits, defineProps } from "vue";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["prompt-sent"]);

const prompt = ref("");
const textareaRef = ref(null);

const submitPrompt = () => {
  if (prompt.value.trim() && !props.disabled) {
    emit("prompt-sent", prompt.value);
    prompt.value = "";
    resetTextareaHeight();
  }
};

const setAndSubmitPrompt = (newPrompt) => {
  if (!props.disabled) {
    prompt.value = newPrompt;
    submitPrompt();
  }
};

const adjustTextareaHeight = () => {
  const textarea = textareaRef.value;
  if (textarea) {
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }
};

const resetTextareaHeight = () => {
  const textarea = textareaRef.value;
  if (textarea) {
    textarea.style.height = "auto";
  }
};

const handleKeyDown = (event) => {
  if (event.key === "Enter" && !event.shiftKey && !props.disabled) {
    event.preventDefault();
    submitPrompt();
  }
};

onMounted(() => {
  adjustTextareaHeight();
});

defineExpose({
  setAndSubmitPrompt,
});
</script>
