<template>
  <div class="min-h-[80vh] flex flex-col">
    <div v-if="!sessionStore.isLoggedIn" class="text-center p-8">
      <h3 class="text-lg font-semibold text-base-content mb-4">
        Please Log In
      </h3>
      <p class="text-base-content opacity-70">
        You need to be logged in to use the chat feature.
      </p>
      <router-link to="/login" class="btn btn-primary mt-4">Log In</router-link>
    </div>

    <div v-else class="flex-grow flex flex-col">
      <div class="flex-grow overflow-y-auto px-4 mb-4">
        <div
          v-if="!hasPromptBeenSent && !isLoadingThread"
          class="text-center mb-8"
        >
          <img
            src="/images/two_bros_inferencing_logo_small.png"
            alt="Logo"
            class="w-16 h-16 mx-auto mb-4"
          />
          <h3 class="text-lg font-semibold text-base-content mb-2">
            2Bros Inferencing
          </h3>
          <p class="text-base-content opacity-70 mb-6">
            Send a message or choose an agent below to get started
          </p>
        </div>

        <div v-if="conversation.length > 0" class="space-y-6">
          <div
            v-for="(message, index) in conversation"
            :key="index"
            class="flex"
            :class="message.type === 'user' ? 'justify-end' : 'justify-start'"
          >
            <div
              class="max-w-[80%] px-4 py-3 rounded-lg"
              :class="
                message.type === 'user'
                  ? 'bg-primary text-primary-content rounded-br-none'
                  : 'bg-base-200 text-base-content rounded-bl-none'
              "
            >
              <div class="flex items-start gap-3">
                <img
                  v-if="message.type === 'ai'"
                  src="/images/two_bros_inferencing_logo_small.png"
                  alt="AI"
                  class="w-6 h-6 mt-1"
                />
                <p class="whitespace-pre-wrap">{{ message.content }}</p>
                <div
                  v-if="message.isLoading"
                  class="loading loading-dots"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!hasPromptBeenSent && !isLoadingThread"
          class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4"
        >
          <ActionCard
            v-for="action in actions"
            :key="action.title"
            :action="action"
            @click="sendInitialPrompt(action.title)"
          />
        </div>
      </div>

      <div class="px-4 py-4 bg-base-200 rounded-b-lg">
        <PromptInput
          ref="promptInputRef"
          @prompt-sent="onPromptSent"
          class="max-w-4xl mx-auto"
          :disabled="isWaitingForResponse"
        />
        <div class="text-xs text-center mt-2 text-base-content opacity-70">
          Press ⌘/Ctrl + K to quickly focus the input
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useSessionStore } from "@/stores/session";
import ActionCard from "@/components/chat/ActionCard.vue";
import PromptInput from "@/components/chat/PromptInput.vue";
import {
  sendMessage,
  pollJobStatus,
  startThread,
  getThread,
} from "@/utilities/api/api.js";
import { toast } from "vue3-toastify";

const POLLING_INTERVAL = 1000;
const sessionStore = useSessionStore();
const router = useRouter();
const route = useRoute();

const actions = ref([
  { icon: "🧑‍🏫", title: "General Inference", color: "text-purple-400" },
  { icon: "✍️", title: "Writer Agent", color: "text-blue-400" },
  { icon: "💬", title: "Social Media Agent", color: "text-yellow-400" },
  { icon: "🤖", title: "Celebrity Impression Agent", color: "text-green-400" },
]);

const hasPromptBeenSent = ref(false);
const isLoadingThread = ref(false);
const promptInputRef = ref(null);
const conversation = ref([]);
const isWaitingForResponse = ref(false);
const threadId = ref(null);
const systemMessage = ref("You are a helpful AI assistant.");
let currentPollingInterval = null;

const loadThreadFromQuery = async () => {
  try {
    const queryThreadId = route.query.thread;
    console.log("Loading thread:", queryThreadId);
    if (queryThreadId) {
      isLoadingThread.value = true;
      const threadResponse = await getThread(queryThreadId);
      console.log("Thread response:", threadResponse);

      threadId.value = threadResponse.threadId;
      systemMessage.value = threadResponse.systemPrompt;
      const { userMessages, responseMessages } = threadResponse.chatHistory;

      console.log("Messages:", { userMessages, responseMessages });

      // Interleave user and response messages
      let i = 0;
      let j = 0;
      while (i < userMessages.length || j < responseMessages.length) {
        if (i < userMessages.length) {
          addToConversation("user", userMessages[i].content);
          i++;
        }
        if (j < responseMessages.length) {
          addToConversation("ai", responseMessages[j].content);
          j++;
        }
      }

      hasPromptBeenSent.value = true; // Consider the conversation started
    }
  } catch (error) {
    console.error("Error loading thread:", error);
    toast.error(error.message || "Error loading thread", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } finally {
    isLoadingThread.value = false;
  }
};

// Watch for changes in the route's query parameters
watch(
  () => route.query,
  () => {
    loadThreadFromQuery();
  },
  { immediate: true }
);

const onPromptSent = async (prompt) => {
  try {
    if (!sessionStore.isLoggedIn) {
      toast.error("Please log in to use the chat feature", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      router.push("/login");
      return;
    }

    hasPromptBeenSent.value = true;
    isWaitingForResponse.value = true;
    addToConversation("user", prompt);
    const loadingMessageIndex = conversation.value.length;
    addToConversation("ai", "Thinking...", true);

    if (!threadId.value) {
      const threadResponse = await startThread(systemMessage.value);
      threadId.value = threadResponse.threadId;

      // Update the URL with the new threadId
      router.push({ query: { thread: threadId.value } }, { replace: true });

      // Explicitly call loadThreadFromQuery to load the thread data
      loadThreadFromQuery();
    }

    const response = await sendMessage({
      threadId: threadId.value,
      userMessage: prompt,
      systemMessage: systemMessage.value,
    });

    startPolling(response.requestId, loadingMessageIndex);
  } catch (error) {
    console.error("Error:", error);
    updateAIMessage(
      conversation.value.length - 1,
      error.message || "An error occurred"
    );
    isWaitingForResponse.value = false;
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
};

const startPolling = async (requestId, messageIndex) => {
  clearInterval(currentPollingInterval);

  currentPollingInterval = setInterval(async () => {
    try {
      const status = await pollJobStatus(requestId);

      if (status.status === "finished" && status.job) {
        clearInterval(currentPollingInterval);
        updateAIMessage(messageIndex, status.job.response);
        isWaitingForResponse.value = false;
      } else if (status.status === "not found") {
        clearInterval(currentPollingInterval);
        updateAIMessage(messageIndex, "Sorry, the response was not found.");
        isWaitingForResponse.value = false;
      }
    } catch (error) {
      console.error("Error polling status:", error);
      clearInterval(currentPollingInterval);
      updateAIMessage(
        messageIndex,
        "Sorry, there was an error getting the response."
      );
      isWaitingForResponse.value = false;
    }
  }, POLLING_INTERVAL);
};

const sendInitialPrompt = async (actionTitle) => {
  switch (actionTitle) {
    case "General Inference":
      systemMessage.value = "You are a helpful AI assistant.";
      break;
    case "Writer Agent":
      systemMessage.value =
        "You are an expert writer who helps with content creation.";
      break;
    case "Social Media Agent":
      systemMessage.value =
        "You are a social media expert who helps create engaging content.";
      break;
    case "Celebrity Impression Agent":
      systemMessage.value =
        "You are an expert at mimicking celebrity speaking styles and mannerisms.";
      break;
  }

  if (promptInputRef.value) {
    promptInputRef.value.setAndSubmitPrompt(actionTitle);
  }
};

const updateAIMessage = (index, content, isLoading = false) => {
  if (index >= 0 && index < conversation.value.length) {
    conversation.value[index] = {
      ...conversation.value[index],
      content,
      isLoading,
    };
  }
};

const addToConversation = (type, content, isLoading = false) => {
  conversation.value = [...conversation.value, { type, content, isLoading }];
  scrollToBottom();
};

const scrollToBottom = () => {
  setTimeout(() => {
    const conversationElement = document.querySelector(".overflow-y-auto");
    if (conversationElement) {
      conversationElement.scrollTop = conversationElement.scrollHeight;
    }
  }, 100);
};

const handleKeyDown = (event) => {
  if ((event.metaKey || event.ctrlKey) && event.key === "k") {
    event.preventDefault();
    document.getElementById("prompt-input")?.focus();
  }
};

onMounted(() => {
  window.addEventListener("keydown", handleKeyDown);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeyDown);
  clearInterval(currentPollingInterval);
});
</script>

<style scoped>
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: theme("colors.base-300") theme("colors.base-100");
}

.overflow-y-auto::-webkit-scrollbar {
  width: 8px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: theme("colors.base-100");
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: theme("colors.base-300");
  border-radius: 4px;
}

.loading {
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 2px solid currentColor;
  border-radius: 50%;
  border-right-color: transparent;
  animation: loading-spin 0.75s linear infinite;
}

@keyframes loading-spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
