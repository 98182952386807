// api.js
import { useSessionStore } from "@/stores/session";
const API_BASE_URL = "https://api.twobrosinferencing.com";

const handleApiResponse = async (response) => {
  if (!response.ok) {
    throw new Error(`API error: ${response.status}`);
  }
  return response.json();
};

export const startThread = async (systemMessage) => {
  const sessionStore = useSessionStore();

  const response = await fetch(`${API_BASE_URL}/start-thread`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": sessionStore.user.apiKey,
    },
    body: JSON.stringify({
      systemPrompt: systemMessage,
    }),
  });
  return handleApiResponse(response);
};

export const sendMessage = async (messageData) => {
  const sessionStore = useSessionStore();

  if (!sessionStore.isLoggedIn || !sessionStore.user?.apiKey) {
    throw new Error("Please log in to use the chat feature");
  }

  const response = await fetch(`${API_BASE_URL}/llm`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": sessionStore.user.apiKey,
    },
    body: JSON.stringify({
      threadId: messageData.threadId,
      userMessage: messageData.userMessage,
      systemMessage: messageData.systemMessage,
      assistantMessage: "",
    }),
  });

  return handleApiResponse(response);
};

export const pollJobStatus = async (requestId) => {
  const sessionStore = useSessionStore();

  if (!sessionStore.isLoggedIn || !sessionStore.user?.apiKey) {
    throw new Error("Please log in to use the chat feature");
  }

  const response = await fetch(
    `${API_BASE_URL}/status?requestId=${requestId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": sessionStore.user.apiKey,
      },
    }
  );

  return handleApiResponse(response);
};
