<!-- SideNavigation.vue -->
<template>
  <nav
    :class="[
      'z-10 fixed left-0 top-24 h-[calc(100vh-10rem)] m-4 py-4 px-2 bg-base-100 bg-opacity-80 text-base-content transition-all duration-300 ease-in-out rounded-xl shadow-lg',
      isExpanded ? 'w-48' : 'w-18',
    ]"
  >
    <div class="flex flex-col h-full">
      <!-- Navigation Items -->
      <ul class="flex-grow">
        <li v-for="item in navigationItems" :key="item.id" class="relative">
          <router-link
            :to="item.path"
            class="w-full py-4 px-2 mt-4 rounded-lg flex items-center hover:text-secondary focus:outline-none focus:ring-2 focus:ring-secondary transition-colors duration-200"
            :class="{
              'bg-neutral': $route.path === item.path,
            }"
            @mouseenter="hoveredItem = item.id"
            @mouseleave="hoveredItem = null"
          >
            <div class="w-10 flex justify-center items-center">
              <font-awesome-icon :icon="item.icon" class="text-xl" />
            </div>
            <span
              v-if="isExpanded"
              class="transition-opacity duration-400 absolute left-14"
              >{{ item.title }}</span
            >
          </router-link>
          <!-- Hover title box -->
          <div
            v-if="!isExpanded"
            class="absolute left-full ml-2 px-3 py-1 bg-neutral rounded-lg shadow-lg text-sm whitespace-nowrap opacity-0 pointer-events-none transition-opacity duration-200"
            :class="{ 'opacity-100': hoveredItem === item.id }"
            style="top: 50%; transform: translateY(-50%)"
          >
            {{ item.title }}
          </div>
        </li>
      </ul>

      <!-- Expand/Collapse Toggle -->
      <button
        @click="toggleExpand"
        class="p-4 rounded-lg w-full flex justify-center items-center hover:text-secondary hover:outline-none hover:ring-2 hover:ring-secondary transition-colors duration-200"
        :aria-label="isExpanded ? 'Collapse menu' : 'Expand menu'"
      >
        <font-awesome-icon
          :icon="isExpanded ? 'chevron-left' : 'chevron-right'"
          class="text-xl"
        />
      </button>
    </div>
  </nav>
</template>

<script setup>
import { ref, computed } from "vue";
import { useSessionStore } from "@/stores/session";
// import { useRoute } from "vue-router";

const sessionStore = useSessionStore();
// const route = useRoute();

const isExpanded = computed({
  get: () => !sessionStore.isSideNavCollapsed,
  set: (value) => sessionStore.setSideNavCollapsed(!value),
});

const hoveredItem = ref(null);

const navigationItems = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: ["fas", "chart-line"],
    path: "/dashboard",
  },
  {
    id: "chat",
    title: "Chat",
    icon: ["fas", "comments"],
    path: "/dashboard/chat",
  },
  {
    id: "ApiKey",
    title: "API Key",
    icon: ["fas", "key"],
    path: "/dashboard/apikey",
  },
  {
    id: "alerts",
    title: "Alerts",
    icon: ["fas", "bell"],
    path: "/dashboard/alerts",
  },
  {
    id: "referrals",
    title: "Referrals",
    icon: ["fas", "user-plus"],
    path: "/dashboard/referrals",
  },
];

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};
</script>

<style scoped>
.router-link-active {
  @apply bg-neutral hover:bg-neutral;
}

li:hover .absolute {
  @apply opacity-100;
}
</style>
